











import { Observer } from 'mobx-vue'
import { Component, Provide, Vue } from 'vue-property-decorator'
import { DeleteViewModel } from './delete-viewmodel'

@Observer
@Component
export default class extends Vue {
  @Provide() vm = new DeleteViewModel()
}
